.button {
  background-color: var(--cl-primary);
  color: var(--cl-background);
  appearance: none;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.button:disabled {
  cursor: default;
  background-color: var(--cl-text);
}

.button[data-style='text'] {
  background-color: transparent;
  color: var(--cl-text);
  padding: 0;
  font-weight: normal;
  font-size: 0.88rem;
  text-transform: uppercase;
}
