"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listen = exports.stringify = exports.parse = exports.update = void 0;
const location = {
    path: globalThis.location.pathname || '/',
    hash: globalThis.location.hash,
    search: globalThis.location.search,
    previous: null,
};
exports.default = location;
const update = (loc) => {
    var _a, _b;
    loc = nonEmpty(loc);
    (_a = loc.search) !== null && _a !== void 0 ? _a : (loc.search = '');
    (_b = loc.hash) !== null && _b !== void 0 ? _b : (loc.hash = '');
    if (typeof loc.search === 'object')
        loc.search = loc.search.toString();
    prefix(loc, 'path', '/');
    prefix(loc, 'hash', '#');
    if (loc.search)
        prefix(loc, 'search', '?');
    loc.previous = (0, exports.stringify)(location);
    Object.assign(location, loc);
    for (const { cb } of listeners)
        cb();
};
exports.update = update;
const prefix = (obj, key, prefix) => {
    if (typeof obj[key] === 'string' &&
        obj[key].length &&
        !obj[key].startsWith(prefix))
        obj[key] = (prefix + obj[key]);
};
const parse = (url) => {
    const parts = url.split(/(?=[?#])/);
    return nonEmpty({
        path: parts.find(v => !/[?#]/.test(v)),
        search: parts.find(v => v.startsWith('?')),
        hash: parts.find(v => v.startsWith('#')),
    });
};
exports.parse = parse;
const stringify = ({ path, search, hash, } = location) => (path || '/') + (search || '') + (hash || '');
exports.stringify = stringify;
const nonEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));
const listeners = [];
const listen = (cb) => {
    const listener = { cb };
    listeners.push(listener);
    return () => {
        const i = listeners.indexOf(listener);
        if (i >= 0)
            listeners.splice(i, 1);
    };
};
exports.listen = listen;
