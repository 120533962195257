.edit {
  width: 50rem;
  max-width: 100vw;
  margin: auto;
  padding: 2rem;
}

.edit > span {
  display: block;
  margin-bottom: 1em;
}

.questions {
  list-style: none;
  padding: 0;
  margin: 4rem 0;
}

.edit button {
  width: fit-content;
}

.title {
  height: 2rem;
  display: flex;
  margin-top: 2rem;
}

.title button {
  height: 100%;
  padding: 0 1em;
  min-width: 7ch;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.title input {
  appearance: none;
  background: none;
  border: none;
  height: 100%;
  border: 1px solid var(--cl-text);
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: inherit;
  font-family: inherit;
  padding: 0 1em;
  color: var(--cl-text);
  width: 100%;
}

.title input:focus {
  outline: none;
}

.question {
  padding-top: 3rem;
  padding-bottom: 1rem;
  border-top: 2px solid var(--cl-text);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.question:last-of-type {
  border-bottom: 2px solid var(--cl-text);
}

.question textarea {
  width: 100%;
}

.saveQuestion {
  align-self: end;
}

.saveQuestion:disabled {
  opacity: 0;
}

.question label {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.answers {
  padding: 0;
  list-style: none;
}

.answerOption {
  display: flex;
  gap: 1em;
}

.answerOption > *:first-child {
  flex-grow: 1;
}
