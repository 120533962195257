"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Matcher = exports.classes = exports.subNamed = exports.toRegex = exports.matchPath = void 0;
const matchPath = (pattern, location) => (pattern instanceof RegExp ? pattern : (0, exports.toRegex)(pattern)).exec(decodeURIComponent(location.path));
exports.matchPath = matchPath;
const toRegex = (matcher) => new RegExp(`^${(0, exports.subNamed)(matcher.replace(/\/$/, ''))}/?$`, 'i');
exports.toRegex = toRegex;
const subNamed = (v) => v.replace(/(\/:([a-z_]\w*))/g, `(?:\\/(?<$2>${exports.classes.param}+))`);
exports.subNamed = subNamed;
exports.classes = {
    param: '\\w',
};
class Matcher {
    constructor(pattern) {
        if (typeof pattern !== 'string') {
            this.path = pattern;
            return;
        }
        const queryPart = /\?[^#</]+/.exec(pattern);
        if (queryPart) {
            pattern =
                pattern.slice(0, queryPart.index) +
                    pattern.slice(queryPart.index + queryPart[0].length);
            this.query = new RegExp(queryPart[0]
                .slice(1)
                .split('&')
                .map(v => `(?=.*[?&]${v})`)
                .join(''), 'i');
        }
        if (pattern)
            this.path = new RegExp(`^${(0, exports.subNamed)(pattern.replace(/\/$/, ''))}/?$`, 'i');
    }
    match(location) {
        var _a;
        let groups = {};
        if (this.path) {
            const pathMatch = this.path.exec(decodeURIComponent(location.path));
            groups = pathMatch && ((_a = pathMatch.groups) !== null && _a !== void 0 ? _a : {});
        }
        if (this.query && !this.query.test(location.search))
            groups = null;
        return groups;
    }
}
exports.Matcher = Matcher;
