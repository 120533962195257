"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const object_1 = require("../utils/object");
// basically an immutable opinionated version of URLSearchParams
// with extra utilities and no support for duplicate keys
class SearchParams {
    constructor(from = {}) {
        if (typeof from === 'object' && !(from instanceof URLSearchParams))
            this.content = Object.fromEntries(Object.entries(from).map(([k, v]) => [
                k,
                typeof v === 'object' && v !== null ? JSON.stringify(v) : v,
            ]));
        else if (from)
            this.content = Object.fromEntries([...new URLSearchParams(from).entries()].map(([k, v]) => [
                k,
                SearchParams.parseValue(v),
            ]));
        else
            this.content = {};
    }
    set(diffs) {
        return new SearchParams(Object.assign(Object.assign({}, this.content), diffs));
    }
    remove(...keys) {
        return new SearchParams((0, object_1.omit)(this.content, ...keys));
    }
    merge(target, reverse = false) {
        const other = target instanceof SearchParams ? target : new SearchParams(target);
        return new SearchParams(reverse
            ? Object.assign(Object.assign({}, this.content), other.content) : Object.assign(Object.assign({}, other.content), this.content));
    }
    static merge(source, target) {
        return new SearchParams(source).merge(target);
    }
    toString() {
        return Object.entries(this.content)
            .map(([k, v]) => (v === '' ? k : `${k}=${v}`))
            .join('&');
    }
    static parseValue(v) {
        if (!v)
            return v;
        if (v === 'undefined')
            return undefined;
        if (v === 'null')
            return null;
        if (v === 'NaN')
            return NaN;
        const n = Number(v);
        if (isNaN(n))
            return v;
        return n;
    }
}
exports.default = SearchParams;
